@import "./admin-template/vars";
@import "./admin-template/index";

.App {
   .loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      background: $dark-purple;
      z-index: 9999;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .MuiDivider-root {
      background-color: $color-white;
   }
   .MuiSvgIcon-root {
      color: $color-white;
   }

   .MuiOutlinedInput-input {
      color: $color-white;
      width: 300px;

      @media screen and (max-width: 420px) {
         width: 160px;
      }
   }

   .container {
      overflow-x: auto;
      margin-right: auto;
      margin-left: auto;
      margin-top: 50px;
      padding: 10px;
      margin: 10px;
   }

   .title {
      margin-top: 20px;
      // text-align: center;
   }

   tr {
      height: 40px !important;
   }

   th {
      max-width: 125px;
      min-width: 125px;
      padding: 0 !important;
      overflow-x: auto;
      white-space: nowrap;
      // text-align: center !important;
   }

   td {
      height: 40px !important;
      padding: 5px !important;
      // text-align: center !important;
   }

   .nav-link.selected {
      color: $neon-green;

      svg {
         color: $neon-green;
      }
   }
}

.css-1xxvxvs-MuiInputBase-root-MuiInput-root:before {
   border-bottom: 1px solid $color-white !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input::placeholder {
   color: white !important;
}

@media screen and (max-width: 575px) {
   .table-footer {
      flex-direction: column;
      align-items: center !important;
      gap: 2rem;
   }
}

// chrome bg autocomplete on input removal
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}
@import '../../../admin-template/vars';

.sign-in {
    min-height: 100vh;
    background-color: $dark-purple;
    background-image: url("../../../assets/PNGs/waves-animation.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    #sign-in-button {
        width: 160px;
    }
}
// Colors
$color-primary: #1cb495 !default;
$color-primary-light: #5defd2 !default;
$color-secondary: #1a82c3 !default;
$color-success: #26DE81 !default;
// $color-success: #67c23a !default;
$color-warning: #eb9e05 !default;
$color-danger: #FF2A6D !default;
$color-info: #878d99 !default;
$color-white: #fff !default;
$color-lightgray: #d3d3d3 !default;

// c8 colors
$light-purple: #382475 !default;
$dark-purple: #1d0040 !default;
$neon-green: #02ffe9 !default;
$neon-purple: #6c17ff !default;
$light-grey: #a1a1a1 !default;
$label-grey: #c8c8c8 !default;
$input-bg: #2b0e4e !default;
$input-border: #422e7f !default;
$counter-bg-color: #2b0966 !default;
$counter-border-color: #5241ac !default;
$lighter-purple: #422a8f !default;
$dashboard-tables-bg: #382475 !default;
$green-text: #26de81 !default;
$yellow-text: #fed330 !default;
$orange-text: #ff5d2a !default;
$pink-text: #f78fb3 !default;
$red-text: #ff2a6d !default;

// Social colors

$color-instagram: #e4405f;
$color-twitter: #55acee;
$color-pinterest: #bd081c;
$color-facebook: #3b5999;
$color-youtube: #cd201f;

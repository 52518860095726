@import '../../admin-template/index';

.onboarding-nav {
    position: relative !important;

    .logo {
        @include img-fluid(100px);

        @media screen and (max-width: 380px) {
            max-width: 70px;
        }
    }
    
    .onboarding-nav-title {
        position: absolute;
        left: 50%;
        top: 48%;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 3px solid #02ffe9;
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 420px) {
        padding-left: 1rem !important;
    }
}
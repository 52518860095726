@import '../../admin-template/vars';

.page-404 {
   height: 100vh;
   background-image: url("../../assets/PNGs/half-globe.jpg");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   padding-left: 1.5rem;
   padding-right: 1.5rem;

   .button-div {
      margin-top: 3rem;
   }

   .particle-effect-btn-div {
      background: #6c17ff;
      color: $color-white;
      padding: 0.7rem 2.75rem;
      border: 1px solid #6c17ff;
      border-radius: 5px;
      cursor: pointer;
      width: 250px;
      text-align: center;

      @media screen and (max-width: 1068px) {
         width: 100%;
         font-size: 16px !important;
      }

      @media screen and (max-width: 320px) {
         font-size: 12px !important;
      }
   }

   .text-center {
      text-align: center;
   }

   .overlay {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 24px;
      padding: 2rem;

      .error {
         font-size: 5rem;

         @media screen and (max-width: 600px) {
            font-size: 3rem;
         }
      }
   }

   .back-btn {
      width: unset;
   }
}
